<template>
  <div>

    <modal-observacoes-agendamento
        :modal-show="modalObservation.modalShow"
        :info-text="modalObservation.infoText"
        :close-modal.sync="modalObservation.modalShow"
    />

    <main class="card hidden_overflow_tabela">
      <div
          v-if="canInsert"
          class="pb-2 px-2 mt-2"
      >
        <b-button
            variant="primary-button"
            @click="redirectToRegister()"
        >
          <feather-icon
              icon="PlusIcon"
              class="mr-50"
          />
          <span class="align-middle">Adicionar novo Agendamento</span>
        </b-button>
      </div>

      <FilterAgendamentoList
          @filterTable="loadScheduleData"
          :filter="filter"
          :errorApi="errorApiSearchParameters"
      />
      <div
          v-if="loadingData"
          class="d-flex justify-content-center my-3"
      >
        <div
            class="spinner-border text-custom-blue"
            role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <section
          v-if="!loadingData"
          class="list-agendamento"
      >
        <b-table
            id="tableAgendamento"
            responsive
            :sticky-column="true"
            :busy.sync="filtering"
            :fields="table.fields"
            :items="table.items"
            no-local-sorting
            :no-sort-reset="true"
            @sort-changed="sortColumn"
        >
          <template #cell(nome_fantasia)="row">
            <div class="width_columns">
              <div
                  v-b-tooltip
                  class="companyScheduling"
                  :title="row.item.nome_fantasia.length > lengthColumnMaximo ? row.item.nome_fantasia : null"
              >
                {{ row.item.nome_fantasia }}
              </div>
              <div
                  v-b-tooltip
                  class="littleLabelScheduling"
                  :title="row.item.cnpj.length > lengthColumnMaximo ? row.item.cnpj : null"
              >
                {{ row.item.cnpj | VMask('##.###.###/####-##') }}
              </div>
            </div>
          </template>
          <template #cell(unidade_descricao)="row">
            <span
                v-b-tooltip
                class="width_columns"
                :title="row.item.descricao && row.item.descricao.length > lengthColumnMaximo ? row.item.descricao : null"
            >
              {{ row.item.unidade_descricao }}
            </span>
          </template>
          <template #cell(data_aplicacao)="row">
            <div class="width_columns">
              <div
                  v-b-tooltip
                  :title="row.item.data_aplicacao && row.item.data_aplicacao.length > lengthColumnMaximo ? row.item.data_aplicacao : null"
              >
                {{ formatDate(row.item.data_aplicacao) }}
              </div>
              <div
                  v-b-tooltip
                  class="littleLabelScheduling"
                  :title="null"
              >
                {{ getPeriod(row.item) }}
              </div>
            </div>
          </template>
          <template #cell(quantidade_dose_prevista)="row">
            <span
                v-b-tooltip
                class="width_columns"
                :title="row.item.quantidade_dose_prevista && row.item.quantidade_dose_prevista.length > lengthColumnMaximo ? row.item.quantidade_dose_prevista : null"
            >
              {{ row.item.quantidade_dose_prevista ? row.item.quantidade_dose_prevista : '-' }}
            </span>
          </template>
          <template #cell(quantidade_dose_aplicada)="row">
            <span
                v-b-tooltip
                class="width_columns"
                :title="row.item.quantidade_dose_aplicada && row.item.quantidade_dose_aplicada.length > lengthColumnMaximo ? row.item.quantidade_dose_aplicada : null"
            >
              {{ row.item.quantidade_dose_aplicada ? row.item.quantidade_dose_aplicada.toLocaleString('pt-BR') : '-' }}
            </span>
          </template>
          <template #cell(situacao)="row">
            <span
                v-b-tooltip
                class="width_columns"
                :class="{
                'customStatusAgendado': row.item.situacao === 'Agendado',
                'customStatusFinalizado': row.item.situacao === 'Finalizado',
                'customStatusCancelado': row.item.situacao === 'Cancelado'
              }"
                :title="row.item.situacao && row.item.situacao.length > lengthColumnMaximo ? row.item.situacao : null"
            >
              {{ row.item.situacao }}
            </span>
          </template>
          <template #cell(actions)="row">
            <div class="width_columns">
              <feather-icon
                  id="view-agendamento"
                  role="button"
                  class="text-custom-blue mr-1"
                  icon="EyeIcon"
                  size="18"
                  @click.prevent="modalObservations(row.item)"
              />
              <feather-icon
                  v-if="canEdit && row.item.situacao == 'Agendado' "
                  id="edit-agendamento"
                  role="button"
                  class="text-custom-blue"
                  icon="EditIcon"
                  size="18"
                  @click.prevent="redirectToEdit(row.item)"
              />
            </div>
          </template>
        </b-table>
        <div class="pb-2 px-1">
          <span
              v-if="withoutAgendamento"
              class="ml-1"
          >Nenhum agendamento encontrado</span>
          <b-col
              sm="12"
              class="mt-2"
          >
            <CustomPagination
                :paginacao="paginationData"
                @page-cliked="updateCurrentPage"
            />
          </b-col>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import {
  BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import { actions, subjects } from '@/libs/acl/rules'
import ModalObservacoesAgendamento from '@/views/components/custom/modals/ObservacoesAgendamento.vue'
import FilterAgendamentoList from '@/views/custom-pages/agendamento/components/FilterAgendamentoList.vue'

export default {
  title: 'Agendamentos',

  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    CustomPagination,
    vSelect,
    ModalObservacoesAgendamento,
    FilterAgendamentoList,
  },

  data() {
    return {
      searchParameters: {
        cnpj: null,
        fantasyName: null,
        date: null,
      },
      errorApiSearchParameters: {
        cnpj: null,
        fantasyName: null,
        date: null,
      },
      filter: false,
      inputDate: null,
      filtering: false,
      canInsert: this.verifyPermissions(actions.INSERIR),
      canEdit: this.verifyPermissions(actions.ATUALIZAR),
      loadingData: false,
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      table: {
        fields: [
          {
            key: 'nome_fantasia', label: 'EMPRESA | CNPJ', sortable: true, class: 'width_columns width_columns_header width_columns_header_company',
          },
          {
            key: 'unidade_descricao', label: 'UNIDADE OPERACIONAL', sortable: true, class: 'width_columns width_columns_header',
          },
          {
            key: 'data_aplicacao', label: 'DATA', sortable: true, class: 'width_columns_small width_columns_header',
          },
          {
            key: 'quantidade_dose_prevista', label: 'DOSES PREVISTAS', sortable: true, class: 'width_columns width_columns_header',
          },
          {
            key: 'quantidade_dose_aplicada', label: 'DOSES APLICADAS', sortable: true, class: 'width_columns width_columns_header',
          },
          {
            key: 'situacao', label: 'STATUS', sortable: true, class: 'width_columns_small width_columns_header',
          },
          {
            key: 'actions', label: 'AÇÕES', sortable: false, class: 'width_columns_small width_columns_header',
          },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'data_aplicacao',
        order: 'desc',
      },
      withoutAgendamento: false,
      modalObservation: {
        modalShow: false,
        infoText: 'Nenhuma observação encontrada.',
      },
      lengthColumnMaximo: 28,
      errors: null,
      searchParams: null,
    }
  },

  methods: {
    redirectToRegister() {
      this.$router.push({ name: 'agendamento-register' })
    },

    verifyPermissions(action) {
      const permission = this.$can(actions[action], subjects.AGENDAMENTO_SESI) || this.$can(actions[action], subjects.AGENDAMENTO_SESI_UO)

      return [permission].includes(true)
    },

    sortColumn(sort) {
      this.table.columnOrder = sort.sortBy
      this.table.order = sort.sortDesc ? 'desc' : 'asc'
      this.loadScheduleData()
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.filter = !this.filter
    },

    redirectToEdit({ id_agendamento }) {
      this.$router.push(
          {
            name: 'agendamento-edit',
            params: {
              idAgendamento: id_agendamento,
            },
          },
      )
    },

    modalObservations(item) {
      this.modalObservation.infoText = item.observacao || item.observacao == '' ? item.observacao : 'Nenhuma observação encontrada.'
      this.modalObservation.modalShow = true
    },

    loadScheduleData(filter = null) {
      this.searchParams = filter ? filter : this.searchParams
      this.paginationData.defaultSize = filter ? filter.porPagina : 10
      if (filter != null) {
        if (filter.hasOwnProperty('currentPage')) {
          this.paginationData.currentPage = filter.currentPage
        }
      }
      this.filtering = true
      this.errorApiSearchParameters.cnpj = null
      this.errorApiSearchParameters.fantasyName = null
      this.errorApiSearchParameters.date = null

      let params
      if(filter) {
        params = {
          cnpj: filter ? filter.cnpj : null,
          nome_fantasia: filter ? filter.nome_fantasia : null,
          data_aplicacao: filter ? filter.data : null,
          situacao: filter ? filter.status : null,
          unidade_operacional: filter ? filter.unidade_operacional : null,
          pagina: filter.pagina ? filter.pagina : this.paginationData.currentPage,
          porPagina: filter.porPagina ? filter.porPagina : this.paginationData.defaultSize,
          colunaNome: this.table.columnOrder,
          colunaOrdem: this.table.order,
        }
      } else {
        params = {
          cnpj: this.searchParams.cnpj ?? this.searchParams.cnpj,
          nome_fantasia: this.searchParams.nome_fantasia ?? this.searchParams.nome_fantasia,
          data_aplicacao: this.searchParams.data ?? this.searchParams.data,
          situacao: this.searchParams.status ?? this.searchParams.status,
          unidade_operacional: this.searchParams.unidade_operacional ?? this.searchParams.unidade_operacional,
          porPagina: this.searchParams.porPagina ? this.searchParams.porPagina : this.paginationData.defaultSize,
          pagina: this.searchParams.pagina ? this.searchParams.pagina : this.paginationData.currentPage,
          colunaNome: this.table.columnOrder,
          colunaOrdem: this.table.order,
        }
      }

      this.$http.get(this.$api.getAndPostAgendamento(), { params }).then(({ data }) => {
        this.table.items = data.data

        this.withoutAgendamento = false

        if (data.data.length == 0) {
          this.withoutAgendamento = true
        }

        params.route = this.$router.currentRoute.name
        this.filtering = false
        this.startPagination(data)
      }).catch(({ response: { data } }) => {
        const error = data.errors
        this.errorApiSearchParameters.cnpj = error.cnpj ? error.cnpj[0] : ''
        this.filtering = false
      })
    },

    startPagination(data) {
      this.paginationData.currentPage = data.current_page
      this.paginationData.totalLines = data.total
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
    },

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    getPeriod(item) {
      let periodo
      if (item.agendamento_periodos.length > 0) {
        periodo = []
        item.agendamento_periodos.forEach(period => periodo.push(period.descricao))
      } else {
        periodo = item.periodo
      }

      let resultPeriod
      if (Array.isArray(periodo)) {
        resultPeriod = periodo.join('/')
      } else {
        resultPeriod = periodo
      }

      return resultPeriod
    },
  },
}
</script>

<style lang="scss" scoped>
.spacing-top-buttons {
  margin-top: 6px;
}
.min-width-label{
  min-width: 250px;
}
.min-width-date{
  min-width: 200px;
}
.min-width-button-search {
  min-width: 140px;
}
.min-width-button-reset {
  min-width: 120px;
}
.customStatusAgendado {
  align-items: center;
  padding: 1px 9px;
  gap: 3px;
  width: 83px;
  height: 20px;
  background: #F3EEFF;
  border-radius: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8C65E2;
}
.customStatusFinalizado {
  padding: 3px 9px;
  background: rgba(40, 199, 111, 0.12);
  border-radius: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #3AAA4E;
}
.customStatusCancelado {
  align-items: center;
  padding: 1px 9px;
  gap: 3px;
  width: 83px;
  height: 20px;
  background: #F3EEFF;
  border-radius: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #EA5555;
}
.companyScheduling {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #5E5873;
}
.littleLabelScheduling {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
}
@media (max-width: 350px) {
  .min-width-button-search {
    min-width: 100px;
  }
  .min-width-button-reset {
    min-width: 100px;
  }
}
.hidden_overflow_tabela{
  overflow: hidden;
}
.width_columns_small{
  min-width: 140px;
  max-width: 140px;
  text-align: left;
}
.width_columns{
  text-align: left;
}
.width_columns_header_company{
  min-width: 220px;
}
.width_columns_header{
  vertical-align: middle !important;
}
</style>