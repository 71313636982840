<template>
    <b-modal
        id="observation-modal-success"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        v-model="modalShow"
    >
        <div class="d-flex flex-column align-items-center justify-center-center my-2">
            <feather-icon
                id="view-modal-success"
                icon="balaoDeObservacao.svg"
                class="mb-2"
                size="80"
            />
            <img 
                id="view-modal-success"
                class="mb-2"
                :src="observationIcon" 
                alt="observationIcon"
            />
            <div class="text-center">
                <div class="observationModalTitle mb-1">Observações</div>
                <div class="observationModalText">{{ infoText }}</div>
            </div>
            <div class="d-flex mt-2">
                <b-button 
                    :disabled="loading" 
                    @click="confirmAction" 
                    variant="primary-button"
                >
                    <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Fechar
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
import observationIcon from "@/assets/custom-icons/balaoDeObservacao.svg";

export default {
    components: {
        BModal, BButton
    },

    props: {
        modalShow: {
            type: Boolean,
            required: true
        },
        infoText: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            loading: false
        }
    },

    methods: {
        confirmAction(modalEvent) {
            modalEvent.preventDefault();

            this.loading = true;

            this.$emit('update:closeModal', false);
        }
    },

    setup() {
      return {
        observationIcon
      };
    },

    watch: {
        modalShow() {
            this.loading = false;
        }
    }
}
</script>
<style lang="scss" scoped>
    .observationModalTitle {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 26px;
        color: #5E5873;
    }
    .observationModalText {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #6E6B7B;
    }
</style>

