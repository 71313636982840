<template>
    <div class="content-wrapper">
        <PageHeader screenName="Agendamentos" :linkItems="linkItems"/>
        <listagem-SESI v-if="verifySESIAccess"/>
        <listagem-AE v-if="verifyAEAccess"/>
    </div>
</template>

<script>

import listagemSESI from "@/views/custom-pages/agendamento/listagens/Listagem-SESI";
import listagemAE from "@/views/custom-pages/agendamento/listagens/Listagem-AE";
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import { actions, subjects } from "@/libs/acl/rules";

export default {

    components: {
        listagemSESI, listagemAE,
        PageHeader
    },

    computed: {
        verifySESIAccess() {
            return this.$can(actions.VISUALIZAR, subjects.AGENDAMENTO_SESI) || this.$can(actions.VISUALIZAR, subjects.AGENDAMENTO_SESI_UO);
        },

        verifyAEAccess() {
            return this.$can(actions.VISUALIZAR, subjects.AGENDAMENTO_AE);
        }
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Agendamentos',
                    active: true
                },
                {
                    name: 'Ver Agendamentos',
                    active: true
                }
           ]
        }
    },

    created() {
        if(!this.verifySESIAccess && !this.verifyAEAccess) {
            this.$router.push({ name: 'misc-not-authorized' })
        }
    }
}
</script>

<style>

</style>